import { DataStore, Predicates, SortDirection } from "aws-amplify/datastore";
// import {Predictions} from "@aws-amplify/predictions";
import {
  TextractClient,
  StartDocumentAnalysisCommand,
  GetDocumentAnalysisCommand,
} from "@aws-sdk/client-textract";
import { alertController } from "@ionic/vue";
import AWS from "aws-sdk";
import { CognitoUserPool } from "amazon-cognito-identity-js";

AWS.config.region = "eu-west-1"; // Region

import { reactive, ref } from "vue";
import { CareAgenda, DevCareModificationOrder, CareOrder } from "@/models";
import { uploadData } from "aws-amplify/storage";

const imageLocation = ref(
  "https://d1fduef2ekbyj7.cloudfront.net/modelImagesFittr/"
);

const cloudImageLocation = ref(
  "https://d1fduef2ekbyj7.cloudfront.net/modelImagesFittr/"
);

const state = reactive({
  submittedOrders: [],
  signedIn: false,
  logged_in_user: "",
  archivedOrders: [],
  modificationOrders: [],
  scrollPosition: 0,
  nextOptions: [],
  imageLocation: imageLocation,
  cloudImageLocation: cloudImageLocation,
  isOpenRef: false,
  stage1: "",
  stage2: "",
  stage3: "",
  tagList: [],
  activeModel: "",
  activeModels: [],
  modelDetailModels: [],
  agenda_items: {},
  selectedDate: "",
  client_numbers: [],
  agendas: "",
  all_agendas: "",
  selectedAgenda: [],
  show_input: false,
  planning: "",
  orders: [],
  loading:false,
});
const methods = {
  async submitPlanning(item) {
    const original = await DataStore.query(CareAgenda, state.selectedAgenda.id);
    if (original) {
      const updatedPost = await DataStore.save(
        CareAgenda.copyOf(original, (updated) => {
          updated.appointments[
            state.selectedAgenda.appointments.findIndex(
              (x) => x.number === item.number
            )
          ].planning = state.planning;
        })
      );
      console.log(updatedPost);
      state.selectedAgenda = structuredClone(updatedPost);
    }
    state.show_input = false;
    // console.log(item);
    //    selectedAgenda.value[
    //      selectedAgenda.value.findIndex((x) => x.number === item.number)
    //    ]
    // .planning = planning.value;

    // console.log(selectedAgenda.value);
  },
  async selectAgenda(item) {
    console.log(item);
    state.selectedAgenda = structuredClone(item);
  },
  async showAgendas() {
    state.selectedAgenda = [];
  },
  async setDate(event) {
    console.log(event);
    state.agendas = await DataStore.query(CareAgenda, (c) =>
      c.and((c) => [c.date.eq(event)])
    );
    console.log(state.agendas);
  },
  async allAgendas() {
    state.all_agendas = await DataStore.query(CareAgenda, Predicates.ALL, {
      sort: (s) => s.date(SortDirection.ASCENDING),
    });
    // all_agendas.value.sort((a, b) => (a.date > b.date) ? 1 : -1)
    // all_agendas.value = await DataStore.query(CareAgenda);
    // console.log(all_agendas);
    state.all_agendas = this.sortDatesDescending(state.all_agendas);
   /* console.log(list);
    console.log(state.all_agendas);*/
  },
  sortDatesDescending(array) {
    return array.sort((a, b) => {
      // Convert date strings to date objects
      const dateA = this.convertToDateObject(a.date);
      const dateB = this.convertToDateObject(b.date);

      // Compare the date objects
      return dateB - dateA;
    });
  },
  convertToDateObject(dateString) {
    const parts = dateString.split("-");
    // Note: months are 0-based in JavaScript Date, so subtract 1
    return new Date(parts[2], parts[1] - 1, parts[0]);
  },
  async choosePicture(event) {
    const file = event.target.files[0];

    const filePath = await uploadData({
      key: file.name,
      data: file,
    });
    console.log(filePath);
    // try {
    //   const result = await getProperties({
    //     key: file.name,
    //   });
    //   console.log('File Properties ', result);
    // } catch (error) {
    //   console.log('Error ', error);
    // }

    // const fileToBlob = async (file) =>
    //   new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
    // const blob = await fileToBlob(file);
    // console.log(blob);

    async function currentSession() {
      state.loading = true;
      const presentAlert = async (err) => {
        const alert = await alertController.create({
          header: 'Something went wrong!',
          message: err,
          buttons: ['OK'],
        });
        state.loading = false;
        await alert.present();
      };

      try {
        const userPool = new CognitoUserPool({
          UserPoolId: "eu-west-1_70bmL721z", // your user pool id here
          ClientId: "3061lm42b3p4ijfkoc4n0lmlra", // your client id here
        });

        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser != null) {
          cognitoUser.getSession(async function (err, session) {
            if (err) {
              console.error(err);
              return;
            }

            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: "eu-west-1:6b946439-f55a-4d6d-b69f-4a4e48472e66", // your identity pool id here
              Logins: {
                // Change the key below according to the specific region your user pool is in.
                "cognito-idp.eu-west-1.amazonaws.com/eu-west-1_70bmL721z":
                  session.getIdToken().getJwtToken(),
              },
            });

            // Make the call to obtain credentials
            AWS.config.credentials.get(async function () {
              async function analyzeDocument() {
                const startCommand = new StartDocumentAnalysisCommand({
                  FeatureTypes: ["FORMS"],
                  DocumentLocation: {
                    S3Object: {
                      Bucket: "care-file-storage75837-staging",
                      Name: `public/${file.name}`,
                    },
                  },
                });

                const client = new TextractClient({
                  region: "eu-west-1",
                  credentials: AWS.config.credentials,
                });

                try {
                  const startResponse = await client.send(startCommand);

                  const getAnalysisResult = async () => {
                    const getCommand = new GetDocumentAnalysisCommand({
                      JobId: startResponse.JobId,
                    });
                    const result = await client.send(getCommand);
                    if (result.JobStatus === "SUCCEEDED") {
                      console.log(result);
                      const klanten = [];
                      let location = "";
                      let consultant = "";
                      let date = "";
                      for (let i = 0; i < result.Blocks.length; i++) {
                        if (
                          result.Blocks[i].BlockType === "LINE" &&
                          result.Blocks[i].Text === "Klant:"
                        ) {
                          const klant = {
                            name: result.Blocks[i + 1].Text,
                            number: result.Blocks[i + 2].Text,
                            planning: "",
                            show_input: false,
                          };
                          klanten.push(klant);
                        }
                        if (
                          result.Blocks[i].BlockType === "LINE" &&
                          result.Blocks[i].Text === "Datum:" &&
                            date === ""
                        ) {
                          // date = new Date(result.Blocks[i + 1].Text.replaceAll("-","/"));
                          date = result.Blocks[i + 1].Text;
                        }
                        if (
                            result.Blocks[i].BlockType === "LINE" &&
                            result.Blocks[i].Text === "Locatie:" &&
                            location === ""
                        ) {
                          location = result.Blocks[i + 1].Text;
                        }
                        if (
                            result.Blocks[i].BlockType === "LINE" &&
                            result.Blocks[i].Text === "Behandelaar:" &&
                            consultant === ""
                        ) {
                          consultant = result.Blocks[i + 1].Text;
                        }
                      }
                      console.log(klanten);
                      const agenda = await DataStore.query(CareAgenda, (c) =>
                          c.and((c) => [
                            c.date.eq(date),
                            c.location.eq(location),
                            c.consultant.eq(consultant),
                          ])
                      );
                      console.log(agenda);
                      if (agenda.length === 0) {
                        const result = await DataStore.save(
                            new CareAgenda({
                              date: date,
                              location: location,
                              consultant: consultant,
                              appointments: JSON.stringify(klanten),
                              editors: ["User"],
                            })
                        );
                        console.log(result);
                        await methods.allAgendas();
                        state.loading = false;
                      }
                    } else if (result.JobStatus === "IN_PROGRESS") {
                      setTimeout(getAnalysisResult, 5000); // retry after 5 seconds
                    } else {
                      presentAlert(result.StatusMessage);
                      console.error("JobStatus: ", result.StatusMessage);
                    }
                  };
                  await getAnalysisResult();
                } catch (err) {
                  presentAlert(err);
                  console.log(err);
                }
              }
              await analyzeDocument();
            });
          });
        }
      } catch (err) {
        presentAlert(err);
        console.log(err);
      }
    }
    await currentSession();
  },
  async getSubmittedOrders() {
    state.submittedOrders = await DataStore.query(
      CareOrder,
      (c) => c.and((c) => [c.status.contains("submitted")]),
      {
        sort: (s) => s.submittedAt(SortDirection.DESCENDING),
        page: 0,
        limit: 50
      },
    );
    state.orders = state.submittedOrders;
    return state.submittedOrders;
  },
  async getModificationOrders() {
    state.modificationOrders = await DataStore.query(
      DevCareModificationOrder,
      // c => c.status('contains', 'submitted'),
      (c) => c.and((c) => [c.status.contains("submitted")]),
      {
        sort: (s) => s.submittedAt(SortDirection.DESCENDING),
        page: 0,
        limit: 50
      }
    );
    state.orders = state.modificationOrders;
    return state.modificationOrders;
  },
  async getArchivedOrders() {
    state.archivedOrders = await DataStore.query(
      CareOrder,
      (c) =>
        c.and((c) => [
          c.status.contains("archived"),
          c.userName.contains(state.logged_in_user.email),
        ]),
      {
        sort: (s) => s.submittedAt(SortDirection.DESCENDING),
      }
    );
    state.orders = state.archivedOrders;
    return state.archivedOrders;
  },
  async searchSubmittedOrders(value) {
    if (value === "") {
      await this.getSubmittedOrders();
    } else {
      const lowerCased = value.replaceAll(" ", "").toLowerCase();
      state.submittedOrders = await DataStore.query(
        CareOrder,
        (c) =>
          c.and((c) => [
            c.search.contains(lowerCased),
            c.status.contains("submitted"),
          ]),
        {
          sort: (s) => s.submittedAt(SortDirection.DESCENDING),
        }
      );
    }
  },
  async searchModificationOrders(value) {
    if (value === "") {
      await this.getModificationOrders();
    } else {
      const lowerCased = value.replaceAll(" ", "").toLowerCase();
      state.modificationOrders = await DataStore.query(
          DevCareModificationOrder,
          (c) =>
              c.and((c) => [
                c.search.contains(lowerCased),
                c.status.contains("submitted"),
              ]),
          {
            sort: (s) => s.submittedAt(SortDirection.DESCENDING),
          }
      );
    }
  },
  async searchArchivedOrders(value) {
    if (value === "") {
      await this.getArchivedOrders();
    } else {
      const lowerCased = value.replaceAll(" ", "").toLowerCase();
      state.archivedOrders = await DataStore.query(
        CareOrder,
        (c) =>
          c
            .search("contains", lowerCased)
            .status("contains", "archived")
            .userName("contains", state.logged_in_user.email),
        {
          sort: (s) => s.submittedAt(SortDirection.DESCENDING),
        }
      );
    }
  },
  setOpen(modalState) {
    ((modalState) => {
      state.isOpenRef = modalState;
    })(modalState);
    console.log(state.isOpenRef);
  },
};
export default {
  imageLocation,
  state,
  methods,
};
