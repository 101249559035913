// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Status = {
  "SUBMITTED": "submitted",
  "ARCHIVED": "archived",
  "PROCESSED": "processed"
};

const { CarePlanningProduct, CareModel, CareAdditionalcost, CareUpperleather, CareClosing, CareOutsole, CareStitchingThickness, CareStitchingcolor, CareConstruction, CareHeeltype, CareHeelmaterial, CareMiscelaneous, CareLining, CareToplift, DevCareOrder, CareModificationItem, DevCareModificationOrder, CareAgenda, CareOrder } = initSchema(schema);

export {
  CarePlanningProduct,
  CareModel,
  CareAdditionalcost,
  CareUpperleather,
  CareClosing,
  CareOutsole,
  CareStitchingThickness,
  CareStitchingcolor,
  CareConstruction,
  CareHeeltype,
  CareHeelmaterial,
  CareMiscelaneous,
  CareLining,
  CareToplift,
  DevCareOrder,
  CareModificationItem,
  DevCareModificationOrder,
  CareAgenda,
  CareOrder,
  Status
};