export default {
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["65"])},
  "LH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladies High"])},
  "LL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladies Low"])},
  "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men Boots"])},
  "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men Low"])},
  "SignInHeaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address and password to log in."])},
  "UpperleatherCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upperleather"])},
  "WP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WP"])},
  "according_model_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["according model"])},
  "accountCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "additional_upper_particularities_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alternitive"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "all_leathers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All numbers"])},
  "all_outsoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Outsoles"])},
  "all_welts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Welts"])},
  "amount_app_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number"])},
  "amount_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number (like in number of eyelets)"])},
  "antislip_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-slip heel"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive!"])},
  "archive_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to temporarily archive the order?"])},
  "archive_order_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived orders can be completed and sent from the order menu at a later time."])},
  "archivedOrdersCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived orders"])},
  "archived_orders_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived Orders"])},
  "as_previous_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["model as before"])},
  "ball_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sole bone thickness"])},
  "ball_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sole bone thickness"])},
  "black_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["black"])},
  "blue_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blue"])},
  "boarding_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height Back"])},
  "boarding_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boarding"])},
  "boarding_front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front Height"])},
  "bottoming_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bottoming"])},
  "brown_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brown"])},
  "cancel_orderpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave the order page? Any entered data will not be saved."])},
  "catalogue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogue"])},
  "category_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHECK!"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "closing_one_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fastening 1"])},
  "closing_three_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fastening 3"])},
  "closing_two_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fastening 2"])},
  "color_1_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun metal"])},
  "color_2_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bronze"])},
  "color_3_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silver"])},
  "color_4_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold"])},
  "color_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["colour"])},
  "confirmCodePasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will send a code to the email address you entered. Use this code to then set a new password on the next screen."])},
  "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm emailaddress"])},
  "construction_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from material"])},
  "construction_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution bottoming"])},
  "construction_execution_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["make way info"])},
  "consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "contrefort_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["counter stiffening"])},
  "customOrderCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom order"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "decorative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decorative"])},
  "decostitching_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["decoration stitching"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "edit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "email_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Address"])},
  "eva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVA"])},
  "eva_rubber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVA/Rubber"])},
  "execution_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution"])},
  "extra_length_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extra lenght+"])},
  "female_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
  "fillsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extra layer"])},
  "forefoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forefoot"])},
  "forgot_password_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "forgot_password_header_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address to reset your password"])},
  "form_soles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
  "functional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functional"])},
  "heel_expansion_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heelform to"])},
  "heel_polster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heelpolster"])},
  "heel_specifics_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heel specifications"])},
  "heel_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thickness heel"])},
  "heel_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thickness heel"])},
  "heelbuffer_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shock absorber heel"])},
  "heelcolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heelcolor"])},
  "heelheight_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heel height"])},
  "heelmaincolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main color heel"])},
  "heelpolster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heel collar polster"])},
  "heelpolster_type_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heelcollar type"])},
  "heelreinforcement_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reinforcement"])},
  "heelrolling_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roller on heel"])},
  "heelsecondcolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second color heel"])},
  "heeltype_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heel type"])},
  "height_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["height"])},
  "insole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insole"])},
  "languageSettingsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change language"])},
  "large_eyelets_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["big eyelets"])},
  "large_hooks_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["big hooks"])},
  "lateral_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lat."])},
  "leather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leather"])},
  "leave_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave order page?"])},
  "leave_order_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave the order page? Any entered data will not be saved."])},
  "left_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left"])},
  "leg_girth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["legmeasurements"])},
  "lining_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vamp lining"])},
  "lining_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lining"])},
  "liningheel_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heelpart lining"])},
  "liningshoe_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liningshoe"])},
  "loggedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed out"])},
  "login_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "low_shoe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low Shoe"])},
  "low_shoe_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low shoe"])},
  "mainstitching_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["main stitches"])},
  "male_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
  "manufacturing_decides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufacturers decision"])},
  "material_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["material"])},
  "medial_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["med."])},
  "midsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Midsole"])},
  "mm_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mm"])},
  "model_information_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Information:"])},
  "modelname_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelname:"])},
  "modelnumber_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelnumber:"])},
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n/a"])},
  "no_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "nose_length_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nose length"])},
  "nose_material_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toe cap"])},
  "not_signed_in_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not logged in!"])},
  "olie_zuurbestendig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oil and Sour resistance"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "openOrdersCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronise orders"])},
  "orderButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order now!"])},
  "orderHistoryCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order history"])},
  "orderHistoryNone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no submitted orders yet!"])},
  "orderMenuCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order menu"])},
  "order_check_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHECK ORDER"])},
  "order_check_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The form is not complete yet!"])},
  "order_check_header_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order is complete!"])},
  "order_check_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p style='display: inline' id='quantity'></p> Items have not been entered completely yet. Use the links below to scroll to the relevant items."])},
  "order_check_message_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press the button below to submit the order. If you don't currently have an internet connection, the order will be saved and shipped later."])},
  "order_complete_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The form is complete!"])},
  "order_complete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press the button below to submit the order. If you do not currently have an internet connection, the order will be saved and shipped later."])},
  "order_details_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order information"])},
  "order_fail_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order cannot be submitted!"])},
  "order_fail_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can save the order with the button at the bottom left of this page and try to send it again at a later time."])},
  "order_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orderform"])},
  "order_history_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order History"])},
  "order_id_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client identification"])},
  "order_id_one_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastnumber"])},
  "order_id_two_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Name"])},
  "order_id_two_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Name"])},
  "order_success_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order has been processed successfully!"])},
  "order_success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If an order cannot be synchronized directly to the database, it will be stored on your device and sent when there is internet connection again."])},
  "order_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order form:"])},
  "organisation_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
  "outsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outsole"])},
  "password_changed_confirmation_before_signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password has been changed successfully, you can now log in with your new password."])},
  "password_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "passwords_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords don't match!"])},
  "pick_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Image"])},
  "polster_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polster/ foam"])},
  "polster_upper_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polster upper"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["print"])},
  "provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivered"])},
  "quarterspace_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quarter opening"])},
  "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining"])},
  "remarks_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
  "reorder_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-order"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "right_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["right"])},
  "rolling_app_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roller"])},
  "rolling_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roller/ addition in bottoming"])},
  "rolling_type_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roller type"])},
  "rolling_type_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roller type"])},
  "rubber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubber"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select"])},
  "sent_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "sharing_not_possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sharing not possible in this browser"])},
  "shore_value_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shorevalue"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "signedin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are signed in!"])},
  "signout_intent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to signout?"])},
  "small_eyelets_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["small eyelets"])},
  "small_hooks_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["small hooks"])},
  "solestiffner_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solestiffening"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "stitching_strength_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yarn"])},
  "take_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take picture"])},
  "thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thickness"])},
  "toe_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thickness toes"])},
  "toe_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thickness toes"])},
  "toes_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toes"])},
  "tonguefixation_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fixation tongue"])},
  "tonguepolster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polster tongue"])},
  "toplift_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["top lift  (heel cap)"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
  "tvo_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVO"])},
  "type_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])},
  "upperSectionCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPPER"])},
  "upper_details_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution upper"])},
  "upper_height_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper height info"])},
  "upperleather_1_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leather 1"])},
  "upperleather_2_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leather 2"])},
  "upperleather_3_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leather 3"])},
  "upperleather_4_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leather 4"])},
  "upperleather_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upperleather"])},
  "user_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "vamppoint_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vamp point"])},
  "vamppoint_caption_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vamp point"])},
  "welt_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welt"])},
  "welt_number_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number"])},
  "yes_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "schachtOnderwerk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed"])},
  "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "hello": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hello ", _interpolate(_named("given_name")), "!"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "polsters_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polsters"])},
  "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])}
}