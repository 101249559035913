<template>
  <ion-page>
    <ion-header color="secondary" class="ion-no-border">
      <ion-toolbar class="ion-no-padding fill color" color="secondary">
        <ion-buttons slot="start" class="ion-padding">
          <ion-back-button></ion-back-button>
          <img
            alt="no-image"
            height="70"
            src="../assets/care_new_logo_white.png"
            style="margin-left: 10px"
          />
        </ion-buttons>
        <ion-buttons slot="end" style="height: 70px; justify-content: center">
          <ion-menu-toggle
            v-if="store.state.signedIn"
            class="ion-margin-end"
            menu="calendar-menu"
          >
            <!--                       <ion-button><i class="fa-light fa-calendar-day"></i></ion-button>-->
            <ion-button>
              <ion-icon
                slot="icon-only"
                :icon="calendarClearOutline"
              ></ion-icon>
            </ion-button>
          </ion-menu-toggle>
          <ion-menu-button
            class="ion-margin-end"
            menu="FtrSideMenu"
          ></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" color="tertiary">
      <ion-grid slot="fixed" style="height: 100%; overflow-y: auto">
        <ion-toolbar style="width: 100%; height: 5%" color="primary">
        </ion-toolbar>
        <ion-row
          class="ion-no-padding ion-no-margin"
          style="z-index: 0; margin: 0; padding: 0; height: 45%"
        >
          <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4" size-xl="4">
            <ion-card
              button
              class="dle ion-no-padding ion-no-margin"
              style="text-align: center"
              @click="showTextLoading($event.target, 'DLE')"
            >
              <img
                style="object-fit: scale-down"
                src="../assets/dames_enkel_laarsjes.jpg"
              />
              <div class="category-text">DAMES ENKELLAARSJES</div>
              <q-inner-loading :showing="visible">
                <ion-spinner></ion-spinner>
              </q-inner-loading>
            </ion-card>
          </ion-col>

          <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4" size-xl="4">
            <ion-card
              button
              class="dlh ion-no-padding ion-no-margin"
              @click="showTextLoading($event.target, 'DLH')"
            >
              <img
                style="object-fit: scale-down"
                src="../assets/dames_laarzen.jpg"
              />
              <div class="category-text">DAMES LAARZEN</div>
              <q-inner-loading :showing="visible">
                <ion-spinner></ion-spinner>
              </q-inner-loading>
            </ion-card>
          </ion-col>

          <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4" size-xl="4">
            <ion-card
              button
              class="ds ion-no-padding ion-no-margin"
              @click="showTextLoading($event.target, 'DS')"
            >
              <img
                style="object-fit: scale-down"
                src="../assets/dames_sneakers.jpg"
              />
              <div class="category-text">DAMES SNEAKERS</div>
              <q-inner-loading :showing="visible">
                <ion-spinner></ion-spinner>
              </q-inner-loading>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row
          class="ion-no-padding ion-no-margin"
          style="z-index: 0; margin: 0; padding: 0; height: 45%"
        >
          <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4" size-xl="4">
            <ion-card
              button
              class="dc ion-no-padding ion-no-margin"
              @click="showTextLoading($event.target, 'DC')"
            >
              <img src="../assets/dames_schoenen.jpg" />
              <div class="category-text">DAMES SCHOENEN</div>
              <q-inner-loading :showing="visible">
                <ion-spinner></ion-spinner>
              </q-inner-loading>
            </ion-card>
          </ion-col>

          <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4" size-xl="4">
            <ion-card
              button
              class="hs ion-no-padding ion-no-margin"
              @click="showTextLoading($event.target, 'HS')"
            >
              <img src="../assets/men_sneakers.jpg" />
              <div class="category-text">HEREN SNEAKERS</div>
              <q-inner-loading :showing="visible">
                <ion-spinner></ion-spinner>
              </q-inner-loading>
            </ion-card>
          </ion-col>

          <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4" size-xl="4">
            <ion-card
              button
              class="hc ion-no-padding ion-no-margin"
              @click="showTextLoading($event.target, 'HC')"
            >
              <img src="../assets/men_schoenen.jpg" />
              <div class="category-text">HEREN SCHOENEN</div>
              <q-inner-loading :showing="visible">
                <ion-spinner></ion-spinner>
              </q-inner-loading>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-toolbar style="width: 100%; height: 5%" color="primary">
        </ion-toolbar>
      </ion-grid>
      <ion-modal ref="modal">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button>Cancel</ion-button>
            </ion-buttons>
            <ion-title>Welcome</ion-title>
            <ion-buttons slot="end">
              <ion-button :strong="true">Confirm</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <ion-item>
            <ion-label position="stacked">Enter your name</ion-label>
            <ion-input
              ref="input"
              type="text"
              placeholder="Your name"
            ></ion-input>
          </ion-item>
        </ion-content>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonSpinner,
  IonMenuToggle,
  IonPage,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonRow,
  IonCard,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonMenuButton,
  IonBackButton,
  IonModal,
  IonButton,
  IonItem,
  IonTitle,
  IonLabel,
  IonInput,
  useIonRouter,
  onIonViewDidLeave,
} from "@ionic/vue";
import { calendarClearOutline } from "ionicons/icons";
import {
  QInnerLoading,
} from "quasar";
import { defineComponent, ref, inject } from "vue";

export default defineComponent({
  name: "HomePage",
  components: {
    QInnerLoading,
    IonButton,
    IonIcon,
    IonMenuToggle,
    IonItem,
    IonTitle,
    IonLabel,
    IonInput,
    IonSpinner,
    IonModal,
    IonPage,
    IonCol,
    IonContent,
    IonGrid,
    IonRow,
    IonCard,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonMenuButton,
    IonBackButton,
  },
  setup() {
    const backButton = ref(null);
    const router = useIonRouter();
    const visible = ref(true);
    const showSimulatedReturnData = ref(false);
    const store = inject("store");

    const props = [
      {
        item: "item",
        logoSrc: "../img/logo-zsm.svg",
      },
    ];

    onIonViewDidLeave(() => {
      setTimeout(() => {
        visible.value = false;
        showSimulatedReturnData.value = true;
      }, 1000);
    });

    return {
      visible,
      showSimulatedReturnData,
      backButton,
      calendarClearOutline,
      router,
      store,
      props,
      loading(target) {
        console.log(target);
      },
      showTextLoading(target, category) {
        target.classList.add("loading");
        visible.value = true;
        showSimulatedReturnData.value = false;
        setTimeout(() => {
          router.push("/models/" + category);
        }, 1000);
      },
    };
  },
});
</script>

<style scoped>
.q-inner-loading {
  background: rgba(0, 0, 0, 0.1);
  visibility: hidden;
}

.loading > .q-inner-loading {
  visibility: visible;
}

ion-page {
  font-family: "century gothic", "Lucida Grande", "Lucida Sans Unicode", Arial,
    Verdana, sans-serif;
}

ion-modal.show-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: 0.9 !important;
}

ion-menu.show-menu {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: 0.9 !important;
}

ion-buttons img {
  margin-left: 15px;
  transition: all 0.5s;
}

ion-list {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.show {
  transform: translateZ(0);
  opacity: 1;
}

.hide {
  transform: translateZ(0);
  opacity: 0;
}

.category-text {
  font-family: "lato-bold", "century gothic", "Lucida Grande",
    "Lucida Sans Unicode", Arial, Verdana, sans-serif;
  position: absolute;
  left: 20%;
  width: 80%;
  margin-left: -10%;
  bottom: 10%;
  opacity: 1;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  box-sizing: border-box;
  font-weight: 500;
  line-height: 1;
  padding: 15px 15px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  background-position: left center;
  background-size: cover;
  background-repeat: no-repeat;
  font-style: normal;
  letter-spacing: normal;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  text-shadow: none;
  user-select: none;
  -webkit-user-select: none;
  white-space: normal;
  color: var(--ion-color-secondary);
  background-color: var(--ion-color-primary);
  border-color: var(--ion-color-secondary);
}

.dle {
  height: 100%;
  width: 100%;
  background-color: var(--ion-color-primary);
  background-blend-mode: exclusion;
}

.dlh {
  height: 100%;
  width: 100%;
  background-color: var(--ion-color-primary);
  background-blend-mode: exclusion;
}

.ds {
  height: 100%;
  width: 100%;
  background-color: var(--ion-color-primary);
  background-blend-mode: exclusion;
}

.dc {
  height: 100%;
  width: 100%;
  background-color: var(--ion-color-primary);
  background-blend-mode: exclusion;
}

.hc {
  height: 100%;
  width: 100%;
  background-color: var(--ion-color-primary);
  background-blend-mode: exclusion;
}

.hs {
  height: 100%;
  width: 100%;
  background-color: var(--ion-color-primary);
  background-blend-mode: exclusion;
}

ion-grid {
  padding: 0;
  margin: 0;
  width: 100%;
}

ion-content #backgroundHomepage {
  min-width: 100%;
  width: 100%;
  background-size: auto;
}

ion-content {
  min-width: 100%;
  width: 100%;
}

ion-card img {
  filter: opacity(0.35);
}

ion-row {
  background: var(--ion-color-tertiary);
  padding: 10px;
  height: 100%;
}

ion-col {
  /*background: var(--ion-color-primary);*/
  width: 100%;
  height: 100%;
  border: 10px solid var(--ion-color-tertiary);
  /*border-bottom: 10px solid var(--ion-color-tertiary);*/
  padding: 0;
  margin: 0;
  /*top: 5%;*/
  /*margin-bottom: 15%;*/
  /*margin-top: 15%;*/
  /*bottom: 30%;*/
}

ion-card {
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
}

@media (max-width: 576px) {
  ion-col {
    height: 50%;
  }
}

@media (min-width: 576px) {
  /*.category-text {*/
  /*  position: absolute;*/
  /*  padding-top: 5px;*/
  /*  padding-bottom: 5px;*/
  /*  left: 50%;*/
  /*  width: 80%;*/
  /*  margin-left: -40%;*/
  /*  bottom: 45px;*/
  /*  text-align: center;*/
  /*  !* background: rgba(121, 136, 145, 0.7); *!*/
  /*  border-radius: 5px;*/
  /*}*/
}
</style>
