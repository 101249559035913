import {createRouter, createWebHistory} from '@ionic/vue-router';

import HomePage from '@/views/HomePage.vue'
// import AdminOrders from '@/views/AdminOrders.vue'
// import AdminHomePage from '@/views/AdminHomePage.vue'
// import ModelPage from '@/views/ModelPage.vue'
// import OrderPage from '@/views/OrderPage.vue'

const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/admin',
        component: () => import("@/views/AdminHomePage.vue"),
        name: 'Admin',
    },
    {
        path: '/modelslist',
        name: 'AdminModelPage',
        component: () => import("@/views/AdminModelPage.vue")
    },
    {
        path: '/item/:item/:table',
        name: 'ItemPage',
        component: () => import("@/views/ItemPage.vue")
    },
    {
        path: '/orders',
        component: () => import("@/views/AdminOrders.vue"),
        props: true,
        name: 'Orders',
    },
    {
        path: '/modification-orders',
        component: () => import("@/views/AdminOrders.vue"),
        props: true,
        name: 'ModificationOrders',
    },
    {
        path: '/print-form/:id',
        component: () => import("@/views/PrintForm.vue"),
        name: 'PrintForm',
        props: true,
    },
    {
        path: '/print-modifications-form/:id',
        component: () => import("@/views/PrintModificationsForm.vue"),
        name: 'PrintModificationsForm',
        props: true,
    },
    {
        path: '/home',
        name: 'Home',
        component: HomePage,
    },
    {
        path: '/custom-shoe-adjustment/:id',
        component: () => import("@/views/ShoeAdjustmentsPage.vue"),
        name: 'ShoeAdjustmentsPage',
        props: true,
    },
    {
        path: '/models/:category',
        component: () => import("@/views/ModelPage.vue"),
        props: true,
        name: 'Models'
    },
    {
        path: '/order/:id',
        component: () => import("@/views/OrderPage.vue"),
        name: 'Order',
        props: true,
    },
    {
        path: '/order/custom/:id',
        component: () => import("@/views/OrderPage.vue"),
        name: 'CustomOrder',
        props: true,
    },
    {
        path: '/home',
        name: 'Home',
        component: HomePage,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
    },
    routes
})

export default router
