export default {
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["65"])},
  "LH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dames Hoog"])},
  "LL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dames Laag"])},
  "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heren Boots"])},
  "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heren Laag"])},
  "SignInHeaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "UpperleatherCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer"])},
  "WP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WP"])},
  "according_model_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als gevraagd model"])},
  "accountCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "additional_upper_particularities_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afwijkend"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles"])},
  "all_leathers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle nummers"])},
  "all_outsoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle zoolmaterialen"])},
  "all_welts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Randen"])},
  "amount_app_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aantal"])},
  "amount_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aantal"])},
  "antislip_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antislip"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiveer!"])},
  "archive_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u de order tijdelijk wilt archiveren?"])},
  "archive_order_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gearchiveerde orders kunt u vanuit het ordermenu op een later tijdstip aanvullen en verzenden."])},
  "archive_order_caption_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen order identificatie"])},
  "archive_order_text_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om de order te archiveren is minimaal een leestnummer of een ander unieke waarde zodat de order herleidbaar is?"])},
  "archivedOrdersCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gearchiveerd"])},
  "archived_orders_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gearchiveerde orders"])},
  "as_previous_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model als gehad"])},
  "ball_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baldikte:"])},
  "ball_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baldikte"])},
  "black_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwart"])},
  "blue_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blauw"])},
  "boarding_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoogte Achterzijde"])},
  "boarding_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stootband"])},
  "boarding_front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoogte Voorzijde"])},
  "bottoming_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderwerk"])},
  "brown_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruin"])},
  "cancel_orderpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xx"])},
  "catalogue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogus"])},
  "category_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
  "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHECK!"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "closing_one_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiting 1"])},
  "closing_three_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiting 3"])},
  "closing_two_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiting 2"])},
  "color_1_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donker gebrand"])},
  "color_2_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licht gebrand"])},
  "color_3_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nikkel"])},
  "color_4_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koper"])},
  "color_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleur"])},
  "confirmCodePasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer hier de (6 cijferige) op je e-mailadres ontvangen bevestigingscode in. (controleer eventueel ook de spambox)"])},
  "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "construction_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opbouw"])},
  "construction_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitvoering onderwerken"])},
  "construction_execution_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maakwijze"])},
  "consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behandelaar"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afronden"])},
  "contrefort_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrefort"])},
  "customOrderCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom order"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "decorative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decoratief"])},
  "decostitching_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierstiksel"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
  "edit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "email_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "eva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVA"])},
  "eva_rubber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVA/Rubber"])},
  "execution_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitvoering"])},
  "extra_length_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra toegift+"])},
  "female_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mw."])},
  "fillsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruimtezool/vulzool"])},
  "forefoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorvoet"])},
  "forgot_password_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "forgot_password_header_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "form_soles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorm"])},
  "functional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functioneel"])},
  "heel_expansion_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakverbreden/Onderschuren"])},
  "heel_polster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hielpolster"])},
  "heel_specifics_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hak specificaties"])},
  "heel_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hieldikte"])},
  "heel_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hieldikte"])},
  "heelbuffer_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakbuffering"])},
  "heelcolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakkleur/materiaal"])},
  "heelheight_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakhoogte"])},
  "heelmaincolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdkleur/materiaal"])},
  "heelpolster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boordpolster"])},
  "heelpolster_type_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boordpolster type"])},
  "heelreinforcement_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versteviging"])},
  "heelrolling_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hak afronding"])},
  "heelsecondcolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweede kleur/materiaal"])},
  "heeltype_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hak type"])},
  "height_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoogte"])},
  "insole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binnenzool"])},
  "internal_ordernumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZSM intern ordernummer"])},
  "languageSettingsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal wijzigen"])},
  "large_eyelets_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grote ringen"])},
  "large_hooks_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grote haken"])},
  "lateral_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lat."])},
  "leather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leder"])},
  "leave_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orderpagina verlaten?"])},
  "leave_order_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u de orderpagina wilt verlaten? Eventuele ingevoerde data wordt niet opgeslagen."])},
  "left_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
  "leg_girth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beenmaten"])},
  "like_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model als gehad"])},
  "lining_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorblad voering"])},
  "lining_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voering"])},
  "liningheel_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtervoering"])},
  "liningshoe_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeringschoen"])},
  "loggedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgelogd"])},
  "login_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "low_shoe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lage Schoen"])},
  "low_shoe_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lage schoen"])},
  "mainstitching_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdstiksel"])},
  "male_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dhr."])},
  "manufacturing_decides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productie bepaald"])},
  "material_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materiaal"])},
  "medial_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["med."])},
  "midsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tussenzool (LET OP! Geen afwikkeling)"])},
  "mm_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mm"])},
  "model_information_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model informatie:"])},
  "modelname_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelnaam:"])},
  "modelnumber_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelnummer:"])},
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n/a"])},
  "no_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
  "nose_length_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuslengte"])},
  "nose_material_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neus versteviging"])},
  "not_signed_in_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet ingelogt!"])},
  "olie_zuurbestendig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olie-Zuurbestendig"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "openOrdersCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forceer order synchronisatie"])},
  "orderButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestel nu!"])},
  "orderHistoryCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order historie"])},
  "orderHistoryNone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn nog geen verzonden orders! "])},
  "orderMenuCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order menu"])},
  "order_check_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xx"])},
  "order_check_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["x"])},
  "order_check_header_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xx"])},
  "order_check_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xx"])},
  "order_check_message_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "order_complete_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het formulier is compleet!"])},
  "order_complete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druk op de onderstaande knop om de order te verzenden. Als je momenteel geen internetverbinding hebt word de order opgeslagen en later verzonden."])},
  "order_details_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Informatie"])},
  "order_fail_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw order kan niet worden verzonden!"])},
  "order_fail_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt de order opslaan met de button links onderaan deze pagina en op een later moment nogmaals proberen te verzenden."])},
  "order_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulier"])},
  "order_history_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Historie"])},
  "order_id_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order identificatie"])},
  "order_id_one_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leestnummer"])},
  "order_id_two_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantnaam"])},
  "order_id_two_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantnaam"])},
  "order_success_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De order is succesvol verwerkt!"])},
  "order_success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer een order niet direct naar de database gesynchroniseerd kan worden, wordt deze opgeslagen op uw apparaat en verzonden wanneer er weer internet verbinding is."])},
  "order_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORDER FORMULIER:"])},
  "organisation_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisatie"])},
  "organisation_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisatie code"])},
  "outsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zool"])},
  "password_changed_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "password_changed_confirmation_before_signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "password_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])},
  "passwords_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoorden komen Niet overeen!"])},
  "pick_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies foto"])},
  "polster_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polstering"])},
  "polster_upper_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hielpolster"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afdrukken"])},
  "provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangeleverd/ bijgeleverd"])},
  "quarterspace_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openstand"])},
  "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rest"])},
  "remarks_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingen"])},
  "reorder_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliceer"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verplicht"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord wijzigen"])},
  "right_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechts"])},
  "rolling_app_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afwikkeling"])},
  "rolling_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afwikkeling/extra laag in onderwerk"])},
  "rolling_type_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afwikkeling type"])},
  "rolling_type_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afwikkeling type"])},
  "rubber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubber"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select"])},
  "sent_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenden"])},
  "shaft_height_including_heelheight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schachthoogte inclusief hakhoogte"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "shore_value_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shorevalue"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
  "signedin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent ingelogd!"])},
  "signout_intent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u wilt uitloggen?"])},
  "small_eyelets_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kleine ringen"])},
  "small_hooks_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kleine haken"])},
  "solestiffner_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoolverstijving"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "stitching_strength_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikte"])},
  "take_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak foto"])},
  "thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikte"])},
  "toe_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teendikte:"])},
  "toe_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teendikte"])},
  "toes_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenen"])},
  "tonguefixation_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tong bevestiging"])},
  "tonguepolster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polster tong"])},
  "toplift_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achterlap"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generaal"])},
  "tvo_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVO"])},
  "type_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "upperSectionCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHACHTEN"])},
  "upper_details_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitvoering schachten"])},
  "upper_height_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoogte schacht (incl. supplement)"])},
  "upperleather_1_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer 1"])},
  "upperleather_2_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer 2"])},
  "upperleather_3_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer 3"])},
  "upperleather_4_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer 4"])},
  "upperleather_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer"])},
  "user_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behandelaar"])},
  "vamppoint_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inschotpunt"])},
  "vamppoint_caption_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inschotpunt"])},
  "welt_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rand"])},
  "welt_number_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nummer"])},
  "yes_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslacht"])},
  "polsters_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polsters"])},
  "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "sharing_not_possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}