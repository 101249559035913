import { createI18n } from "vue-i18n/dist/vue-i18n.esm-bundler.js"
import nl from "./locales/nl.json";
import en from "./locales/en.json";

const i18n = createI18n({
    legacy: false,
    locale: 'nl',
    fallbackLocale: 'en',
    globalInjection: true,
    runtimeOnly: false,
    messages: {
        en,
        nl,
    },
});

export default i18n;